import { Colors } from 'constants/ui/colors';
import { Spacing } from 'constants/ui/spacing';
import React from 'react';
import styled, { CSSProperties } from 'styled-components';

const ActionButton = (props: ActionButtonProps) => {
  const {
    title = 'GET CODE',
    onClick,
    isDisabled = false,
    isLoading = false,
    wrapperStyle,
  } = props;

  return (
    <Wrapper style={wrapperStyle}>
      <Button
        disabled={isDisabled || isLoading}
        $isLoading={isLoading}
        onClick={() => !isDisabled && !isLoading && onClick && onClick()}
      >
        {title}
      </Button>
    </Wrapper>
  );
};

export default ActionButton;

interface CommonProps {
  $isActive?: boolean;
  $isDisabled?: boolean;
  $isLoading?: boolean;
}

interface ActionButtonProps {
  title?: string;
  onClick?: () => void;
  isLoading?: boolean | undefined;
  isDisabled?: boolean | undefined;
  wrapperStyle?: CSSProperties;
}

export const Button = styled.button<CommonProps>`
  all: unset;
  position: relative;
  width: 100%;

  max-height: 44px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background-color: ${(p) =>
    p.$isLoading
      ? Colors.action90
      : p?.disabled
      ? Colors.disabled
      : Colors.action};
  border-radius: 1rem;

  padding: ${Spacing.md} 0;

  color: ${Colors.white};
  text-align: center;
  font-weight: 500;
`;

const Wrapper = styled.div``;
