import { api } from '../constants/api';
import qs from 'query-string';

const URLs = {
  getCompanyInfo: api.endpoints.company.getCompanyInfo,
  createPremiumCode: api.endpoints.company.createPremiumCode,
};

const access_token = process.env.REACT_APP_ACCESS_TOKEN;

async function getCompanyInfo(companyName: string) {
  const params = {
    domain: companyName,
  };

  const url = qs.stringifyUrl(
    {
      url: URLs.getCompanyInfo,
      query: params,
    },
    { skipNull: true }
  );

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${access_token}`,
  };

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers,
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching company info:', error);
    throw error;
  }
}
async function createEmployeePremiumCode(email: string, domain: string) {
  const body = JSON.stringify({
    email,
    domain,
    language: 'tr',
  });

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${access_token}`,
  };

  try {
    const response = await fetch(URLs.createPremiumCode, {
      method: 'POST',
      headers,
      body,
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching company info:', error);
    throw error;
  }
}

export { getCompanyInfo,createEmployeePremiumCode };
