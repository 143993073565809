interface Size {
  smallMobileScreen: string;
  mobileScreen: string;
  bigTablet: string;
  tablet: string;
  laptop: string;
  desktop: string;
  bigScreens: string;
}

const size: Size = {
  smallMobileScreen: "375px", // for small screen mobile
  mobileScreen: "480px", // for mobile screen
  tablet: "768px", // for tablets
  bigTablet: "992px", // for big tablets
  laptop: "1280px", // for laptops
  desktop: "1440px", // for desktop / monitors
  bigScreens: "1920px", // for big screens
};

export const Breakpoints = {
  upTo: {
    smMobileScreen: `(max-width: ${size.smallMobileScreen})`,
    mobileScreen: `(max-width: ${size.mobileScreen})`,
    tablet: `(max-width: ${size.tablet})`,
    bigTablet: `(max-width: ${size.bigTablet})`,
    laptop: `(max-width: ${size.laptop})`,
    desktop: `(max-width: ${size.desktop})`,
    bigScreens: `(max-width: ${size.bigScreens})`,
  },
};
