import { useParams } from 'react-router-dom';
import LandingLayout from 'layouts/landing-layout';
import { AppLogoAndNamePNG } from 'assets/images/image-resources';
import styled from 'styled-components';

import ActionButton from 'components/action-button/action-button';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

const Instructions = () => {
  const { t } = useTranslation();
  const { code } = useParams();

  const onClick = async () => {
    window.open('https://moovbuddytest.page.link/cS5F');
  };

  const copyCodeToClipboard = () => {
    const codeElement = document.getElementById('code');
    if (codeElement) {
      const codeText = codeElement.innerText;
      navigator.clipboard.writeText(codeText);
    }
    toast.success(t('TEXT_COPIED'));
  };

  return (
    <LandingLayout>
      <AppLogosWrapper>
        <LogoWrapper>
          <MBLogo src={AppLogoAndNamePNG} alt='moovbuddy' />
        </LogoWrapper>
        {/* <MBLogo src={companyInfo?.company_logo} alt={companyInfo?.company_domain} /> */}
      </AppLogosWrapper>
      <ContentWrapper>
        <Title>{t('WELCOME_MOOVBUDDY')}</Title>
        <Description>{t('INSTRUCTIONS_DESCRIPTION')}</Description>
        <Description>{t('CODE_TITLE')}</Description>

        <CodeWrapper>
          <Code id='code' onClick={copyCodeToClipboard}>
            {code}
          </Code>
        </CodeWrapper>

        <Description>{t('INSTRUCTIONS_TITLE')}</Description>
        <Description>
          <strong>{t('INSTRUCTIONS_STEP_1_TITLE')}</strong> <br />
          {t('INSTRUCTIONS_STEP_1')}
        </Description>
        <Description>
          <strong>{t('INSTRUCTIONS_STEP_2_TITLE')}</strong>
          <br />
          {t('INSTRUCTIONS_STEP_2')}
        </Description>
        <Description>
          <strong>{t('INSTRUCTIONS_STEP_3_TITLE')}</strong>
          <br />
          {t('INSTRUCTIONS_STEP_3')}
        </Description>
        <Description>
          <strong>{t('INSTRUCTIONS_STEP_4_TITLE')}</strong>
          <br />
          {t('INSTRUCTIONS_STEP_4')}
        </Description>
        <Description>
          <strong>{t('INSTRUCTIONS_STEP_5_TITLE')}</strong>
          <br />
          {t('INSTRUCTIONS_STEP_5')}
        </Description>
        <Description>
          <strong>{t('INSTRUCTIONS_STEP_6_TITLE')}</strong>
          <br />

          {t('INSTRUCTIONS_STEP_6')}
        </Description>
        <Description>
          <strong>{t('INSTRUCTIONS_STEP_7_TITLE')}</strong>
          <br /> {t('INSTRUCTIONS_STEP_7')}
        </Description>
        <Description>
          <strong>{t('INSTRUCTIONS_STEP_8_TITLE')}</strong>
        </Description>

        <ActionButton
          title={t('DOWNLOAD_MOOVBUDDY')}
          wrapperStyle={{ marginTop: '2rem' }}
          onClick={onClick}
        />
      </ContentWrapper>
    </LandingLayout>
  );
};

export default Instructions;

const AppLogosWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 1.5rem;
  margin-top: 4rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;
  padding-bottom: 2rem;
`;

const Title = styled.h1`
  font-size: 22px;
  font-weight: 600;
  text-align: left;
  line-height: 22px;

  color: rgba(0, 16, 51, 1);
`;
const Description = styled.p<{ bold?: boolean }>`
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  text-align: left;
  color: rgba(57, 73, 107, 1);
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
`;

const MBLogo = styled.img`
  height: 100%;
  width: auto;
`;
const LogoWrapper = styled.div`
  height: 40px;
`;

const CodeWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const Code = styled.div`
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  background-color: rgba(172, 196, 216, 0.5);
  padding: 1rem 2rem;
  border-radius: 1rem;
  font-weight: 500;
`;
