import { useEffect, useState } from 'react';
import {
  createEmployeePremiumCode,
  getCompanyInfo,
} from '../../services/user-api';
import { useNavigate, useParams } from 'react-router-dom';
import LandingLayout from 'layouts/landing-layout';
import { AppLogoAndNamePNG } from 'assets/images/image-resources';
import styled from 'styled-components';
import { Colors } from 'constants/ui/colors';
import { FontSizes } from 'constants/ui/font-sizes';
import ActionButton from 'components/action-button/action-button';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

const Langing = () => {
  const [companyInfo, setCompanyInfo] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<any>(true);
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { domain: companyName } = useParams();

  useEffect(() => {
    async function fetchData() {
      if (companyName) {
        try {
          const data = await getCompanyInfo(companyName);
          setCompanyInfo(Object.values(data.data)[0]);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, []);

  const handleSubmit = async () => {
    const emailDomain = email.split('@')[1];

    if (emailDomain !== 'teknasyon.com') {
      setEmailError(true);
      return;
    }
    if (email && companyInfo) {
      try {
        const data = await createEmployeePremiumCode(
          email,
          companyInfo.company_domain
        );
        if (data) {
          let code = data.data.code;
          navigate(`/result/${code}`);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  if (!companyInfo && !isLoading) {
    return <div>Company Not found</div>;
  }

  return (
    <LandingLayout>
      <AppLogosWrapper>
        <LogoWrapper>
          <MBLogo src={AppLogoAndNamePNG} alt='moovbuddy' />
        </LogoWrapper>
        {companyInfo && (
          <CompanyLogoWrapper small={true}>
            <MBLogo
              src={companyInfo?.company_logo}
              alt={companyInfo?.company_domain}
            />
          </CompanyLogoWrapper>
        )}
      </AppLogosWrapper>
      <ContentWrapper>
        <Title>{t('LANDING_TITLE')}</Title>
        <Description>{t('LANDING_DESCRIPTION')}</Description>

        <InputWrapper>
          <EmailInput
            required
            name='user-email-input'
            id='user-email-input'
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError(false); // Reset email error on input change
            }}
            value={email}
            placeholder={'johndoe@gmail.com'}
            autoCapitalize='off'
            autoFocus={true}
            type='email'
            inputMode='email'
            autoComplete='email'
            isError={emailError} // Pass email error state to input component
          />
          <ErrorMessage isVisible={emailError}>
            {t('INVALID_MAIL')}
          </ErrorMessage>
        </InputWrapper>
        <ActionButton
          isLoading={isLoading}
          isDisabled={!email || emailError}
          title={t('GET_CODE')}
          wrapperStyle={{ marginTop: '3rem' }}
          onClick={handleSubmit}
        />
      </ContentWrapper>
    </LandingLayout>
  );
};

export default Langing;

const AppLogosWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 1.5rem;
  margin-top: 4rem;
`;
const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;
`;

const Title = styled.h1`
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  line-height: 22px;

  color: rgba(0, 16, 51, 1);
`;
const Description = styled.p`
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  text-align: center;
  color: rgba(57, 73, 107, 1);
`;

const MBLogo = styled.img`
  height: 100%;
  width: auto;
`;
const LogoWrapper = styled.div<{ small?: boolean }>`
  height: ${(props) => (props.small ? '30px' : '40px')};
`;
const CompanyLogoWrapper = styled.div<{ small?: boolean }>`
  height: ${(props) => (props.small ? '30px' : '40px')};
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
`;

const EmailInput = styled.input<{ isError: boolean }>`
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 5px;
  width: 100%;
  border: ${(props) =>
    props.isError ? `1.5px solid ${Colors.red500}` : '1.5px solid #d0d5e0'};

  &::placeholder {
    color: ${Colors.gray400};
    font-size: ${FontSizes['2xs']};
  }
`;

const ErrorMessage = styled.div<{ isVisible: boolean }>`
  color: ${Colors.red500};
  font-size: ${FontSizes['3xs']};
  margin-top: 0.5rem;
  position: absolute;
  bottom: -1.1rem;
  left: 0;
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
`;
const InputWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
`;
