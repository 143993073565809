const MAIN_URL = process.env.REACT_APP_API_URL;

export const api = {
  endpoints: {
    company: {
      getCompanyInfo: MAIN_URL + '/get-company-information',
      createPremiumCode: MAIN_URL + '/create-employee-health-premium-code',
    },
  },
};
