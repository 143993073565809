import { Toaster } from 'react-hot-toast';
import AppRoutes from './router';
import { I18nextProvider } from 'react-i18next';
import i18n from 'localization/i18n';

const App = () => {
  return (
    <>
      <I18nextProvider i18n={i18n} defaultNS={'translation'}>
        <Toaster />
        <AppRoutes />
      </I18nextProvider>
    </>
  );
};

export default App;
