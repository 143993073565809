export const Spacing = {
  none: 0,

  /* Negative */
  "-6xs": "-0.8px",
  "-5xs": "-1.6px",
  "-4xs": "-2.4px",
  "-3xs": "-3.2px",
  "-2xs": "-4.8px",
  "-xs": "-6.4px",
  "-sm": "-9.6px",
  "-md": "-12.8px",
  "-lg": "-16px",
  "-xl": "-20.8px",
  "-2xl": "-25.6px",
  "-3xl": "-30.4px",
  "-4xl": "-41.6px",
  "-5xl": "-51.2px",

  /* Positive */
  "6xs": "0.8px",
  "5xs": "1.6px",
  "4xs": "2.4px",
  "3xs": "3.2px",
  "2xs": "4.8px",
  xs: "6.4px",
  sm: "9.6px",
  md: "12.8px",
  lg: "16px",
  xl: "20.8px",
  "2xl": "25.6px",
  "3xl": "30.4px",
  "4xl": "41.6px",
  "5xl": "51.2px",
};
