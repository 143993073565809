import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Langing from '../pages/landing';
import Instructions from 'pages/Instructions';

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/:domain' element={<Langing />} />
        <Route path='/result/:code' element={<Instructions />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
 