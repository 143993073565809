export const Colors = {
  background: "#EDF3FB",
  background2: "#CFCDC9",
  background3: "#F0EEEA",

  /*  */
  error: "#E74C3C",
  warning: "#F8C808",
  warningDark: "#C69400",
  success: "#2ECC71",
  successDark: "#19A153",
  info: "#3498DB",

  border:'#C3CEE6',
  modalBackground:"#ffffff",

  /*  */
  purple: "#6C5DD3",
  lightPurple: "#988AFF",
  premiumColor: "#F8C128",
  lightBlue: "#7FDCF0",
  logoBlue: "#3B5998",
  yellow: "#FFBF00",
  pink: "#FF7461",
  lightPink: "#FFA7A3",
  optionPink: "#FF8E73",

  webGreen: "#16B693",

  title: "#001033",
  title10: "#00103310",
  title20: "#00103320",
  title30: "#00103330",
  title40: "#00103340",
  title50: "#00103350",
  title60: "#00103360",
  title70: "#00103370",
  title80: "#00103380",
  title90: "#00103390",

  body: "#39496b",
  body10: "#39496b10",
  body20: "#39496b20",
  body30: "#39496b30",
  body40: "#39496b40",
  body50: "#39496b50",
  body60: "#39496b60",
  body70: "#39496b70",
  body80: "#39496b80",
  body90: "#39496b90",

  subtitle: "#506083",
  subtitle10: "#50608310",
  subtitle20: "#50608320",
  subtitle30: "#50608330",
  subtitle40: "#50608340",
  subtitle50: "#50608350",
  subtitle60: "#50608360",
  subtitle70: "#50608370",
  subtitle80: "#50608380",
  subtitle90: "#50608390",

  subtitleSecondary: "#8995A3",
  subtitleSecondary10: "#8995A310",
  subtitleSecondary20: "#8995A320",
  subtitleSecondary30: "#8995A330",
  subtitleSecondary40: "#8995A340",
  subtitleSecondary50: "#8995A350",
  subtitleSecondary60: "#8995A360",
  subtitleSecondary70: "#8995A370",
  subtitleSecondary80: "#8995A380",
  subtitleSecondary90: "#8995A390",

  action: "#1558E6",
  action10: "#1558E610",
  action20: "#1558E620",
  action30: "#1558E630",
  action40: "#1558E640",
  action50: "#1558E650",
  action60: "#1558E660",
  action70: "#1558E670",
  action80: "#1558E680",
  action90: "#1558E690",

  actionSecondary: "#1558E6",
  actionSecondary10: "#1558E610",
  actionSecondary20: "#1558E620",
  actionSecondary30: "#1558E630",
  actionSecondary40: "#1558E640",
  actionSecondary50: "#1558E650",
  actionSecondary60: "#1558E660",
  actionSecondary70: "#1558E670",
  actionSecondary80: "#1558E680",
  actionSecondary90: "#1558E690",

  disabled: "#C1C3C7",
  disabled10: "#C1C3C710",
  disabled20: "#C1C3C720",
  disabled30: "#C1C3C730",
  disabled40: "#C1C3C740",
  disabled50: "#C1C3C750",
  disabled60: "#C1C3C760",
  disabled70: "#C1C3C770",
  disabled80: "#C1C3C780",
  disabled90: "#C1C3C790",

  blue50: "#01071a",
  blue100: "#02123c",
  blue200: "#0933ae",
  blue300: "#0f47f1",
  blue400: "#2a5cf2",
  blue500: "#3d6bf4",
  blue600: "#7091f6",
  blue700: "#93acf8",
  blue800: "#aec1f9",
  blue900: "#cdd8fb",
  "blue500-10": "#3d6bf410",
  "blue500-30": "#3d6bf430",
  "blue500-50": "#3d6bf450",

  black10: "rgba(0,0,0,0.1)",
  black20: "rgba(0,0,0,0.2)",
  black30: "rgba(0,0,0,0.3)",
  black40: "rgba(0,0,0,0.4)",
  black50: "rgba(0,0,0,0.5)",
  black60: "rgba(0,0,0,0.6)",
  black70: "rgba(0,0,0,0.7)",
  black80: "rgba(0,0,0,0.8)",
  black90: "rgba(0,0,0,0.9)",
  black: "#000000",

  white10: "rgba(255,255,255,0.1)",
  white20: "rgba(255,255,255,0.2)",
  white30: "rgba(255,255,255,0.3)",
  white40: "rgba(255,255,255,0.4)",
  white50: "rgba(255,255,255,0.5)",
  white60: "rgba(255,255,255,0.6)",
  white70: "rgba(255,255,255,0.7)",
  white80: "rgba(255,255,255,0.8)",
  white90: "rgba(255,255,255,0.9)",
  white: "#ffffff",

  yellow50: "#090801",
  yellow100: "#191700",
  yellow200: "#4b4302",
  yellow300: "#695e02",
  yellow400: "#776b03",
  yellow500: "#847603",
  yellow600: "#a89703",
  yellow700: "#c3af03",
  yellow800: "#d9c303",
  yellow900: "#f2da04",

  red50: "#190200",
  red100: "#310400",
  red200: "#880e02",
  red300: "#bb1303",
  red400: "#d41503",
  red500: "#e91703",
  red600: "#fd6354",
  red700: "#fd9086",
  red800: "#fdaea7",
  red900: "#fdcdc9",
  "red500-10": "#e9170310",
  "red500-30": "#e9170330",
  "red500-50": "#e9170350",

  tabGray: "#C9CFDA",
  gray3: "#F4F5F6",
  gray500: "#A2A4A8",

  gray400: "#C1C3C7",
  gray300: "#E4E7EF",
  gray200: "#EFF1F5",
  gray100: "#F4F6FA",

  green50: "#020b05",
  green100: "#001a0b",
  green200: "#044d25",
  green300: "#036c34",
  green400: "#047b3b",
  green500: "#048841",
  green600: "#04ad52",
  green700: "#03c95f",
  green800: "#12de71",
  green900: "#01f98d",

  orange100: "#fffaf0",
  orange200: "#feebc8",
  orange300: "#fbd38d",
  orange400: "#f6ad55",
  orange500: "#ed8936",
  orange600: "#dd6b20",
  orange700: "#c05621",
  orange800: "#9c4221",
  orange900: "#7b341e",

  teal100: "#e6fffa",
  teal200: "#b2f5ea",
  teal300: "#81e6d9",
  teal400: "#4fd1c5",
  teal500: "#38b2ac",
  teal600: "#319795",
  teal700: "#2c7a7b",
  teal800: "#285e61",
  teal900: "#234e52",

  indigo100: "#ebf4ff",
  indigo200: "#c3dafe",
  indigo300: "#a3bffa",
  indigo400: "#7f9cf5",
  indigo500: "#667eea",
  indigo600: "#5a67d8",
  indigo700: "#4c51bf",
  indigo800: "#434190",
  indigo900: "#3c366b",

  purple100: "#faf5ff",
  purple200: "#e9d8fd",
  purple300: "#d6bcfa",
  purple400: "#b794f4",
  purple500: "#9f7aea",
  purple600: "#805ad5",
  purple700: "#6b46c1",
  purple800: "#553c9a",
  purple900: "#44337a",

  transparent: "rgba(0,0,0,0)",
};
