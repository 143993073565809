import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import localeEN from './locales/en.json';
import localeTR from './locales/tr.json';

import { AppConfigs } from '../constants/app-configs';

const resources = {
  en: {
    translation: localeEN,
  },

  tr: {
    translation: localeTR,
  },
};

i18n
  //   .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  //.init<HttpBackendOptions>({
  .init({
    resources,
    // Kullanılacak dilleri belirtir.
    // Varsayılan dil
    fallbackLng: AppConfigs.defaultLanguage,
    // Çeviri dosyalarını yükleyeceği backend ayarları
    /*     backend: {
      // Çeviri dosyalarının yükleneceği URL
      loadPath: "http://192.168.1.10:4000/langJSObject/{{lng}}",
    }, */

    interpolation: {
      escapeValue: false, // React zaten XSS'ye karşı koruma sağlar
    },
    // React'i kullanırken gereklidir
  });

export default i18n;
