export const FontSizes = {
  "5xs": "8px",
  "4xs": "10px",
  "3xs": "12px",
  "2xs-5": "12.4px",
  "2xs-4": "12.8px",
  "2xs-3": "13.2px",
  "2xs-2": "13.6px",
  "2xs": "14px",
  xs: "16px",
  sm: "18px",
  md: "20px",
  "md-2": "20.8px",
  "md-3": "21.6px",
  "md-4": "22.4px",
  "md-5": "23.2px",
  lg: "24px",
  xl: "28px",
  "2xl": "32px",
  "3xl": "36px",
  "4xl": "40px",
  "5xl": "48px",
  "6xl": "56px",
  "7xl": "60px",
  "8xl": "64px",
  "9xl": "72px",
  "10xl": "80px",
};
