import styled, { keyframes } from 'styled-components';
import { WidthConstants } from 'constants/ui/width';
import { Breakpoints } from 'constants/ui/breakpoints';
import { Spacing } from 'constants/ui/spacing';
import { Container } from 'react-bootstrap';

const LandingLayout = (props: LayoutProps) => {
  const { children } = props;

  return (
    <Wrapper>
      <StyledContainer>{children}</StyledContainer>
    </Wrapper>
  );
};

export default LandingLayout;

interface LayoutProps {
  children?: React.ReactNode;
}

const fade = keyframes`
from {
  opacity: 0;
}

to {
  opacity: 1;
}
`;

const StyledContainer = styled(Container)`
  width: 100%;
  display: flex;
  overflow-x: visible;
  flex-direction: column;
  justify-content: flex-start;
  // max-width: ${WidthConstants.quizContainerMaxWidthTablet};
  max-width: ${WidthConstants.quizContainerMaxWidth};
  animation: ${fade} 0.25s ease;
  @media ${Breakpoints.upTo.tablet} {
    max-width: ${WidthConstants.quizContainerMaxWidth};
    padding: 0 ${Spacing.xl};
  }
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background: linear-gradient(90deg, #e2f1f9, #c7e1fe);
`;
